import React, { useCallback, useState, useEffect } from 'react'
import { hooks } from '@front/volcanion'
import moment from 'moment'

import FormatUtils from '@front/squirtle/utils/format'

import { Loader } from '@front/squirtle'
import Callbacks from './callbacks'
import OnKeyDown from './onKeyDown'

const withContainer = Component => ({ event_id, ...props }) => {
  const { EXTERNAL_PHONE_SERVICE } = hooks.useRelayConstants(['EXTERNAL_PHONE_SERVICE'])

  const { openNotification } = hooks.useNotification()
  const [, { openDialog }] = hooks.useDialogs()
  const [handleEvent] = hooks.useModelFunction('event', 'handle')
  const [incident, { isLoading, isReady }] = hooks.useModel('event', [event_id], {
    populate: [
      'order.active_transport.vehicle.current_location',
      'order.source',
      'order.destination',
      'order.loads',
      'order.do.auth',
      'driver.info',
      'driver.auth',
      'vehicle.driver.info',
      'vehicle.parent_license.usual_vehicle.driver.info',
    ],
    single: true
  })

  const [[setting_limit]] = hooks.useModelSearch('setting', 'get', {
    initial_filter: {},
    forced_filter: { name: `${incident?.name}_limit` },
    validate_filter: useCallback(() => !!incident?.name && !!isReady, [incident?.name, isReady]),
    watchers: [incident?.name, isReady]
  })

  const [[setting_period], , { isLoading: isSettingPeriodLoading }] = hooks.useModelSearch('setting', 'get', {
    initial_filter: {},
    forced_filter: { name: `${incident?.name}_period` },
    validate_filter: useCallback(() => !!incident?.name && !!isReady, [incident?.name, isReady]),
    watchers: [incident?.name, isReady]
  })

  const [] = hooks.useModelSearch('event', 'get', {
    initial_filter: {},
    enableCount: true,
    search_id: 'search_linked_incident',
    forced_filter: {
      driver: incident?.driver?.user_id,
      createdAt: FormatUtils.intervalFilter(moment.utc().subtract(setting_period?.value, 'd').format(), moment.utc().format()),
      name: incident?.name,
      action: 'approve'
    },
    validate_filter: useCallback(() => !!incident?.driver?.user_id && !!setting_period?.value && !isSettingPeriodLoading, [incident?.driver?.user_id, setting_period?.value, isSettingPeriodLoading]),
    watchers: [incident?.driver?.user_id, setting_period?.value, isSettingPeriodLoading]
  })

  const incidentBadgeContent = hooks.useSearchListenerCount('event', 'search_linked_incident')

  const [openOrder, setOpenOrder] = useState(false)
  const [openMap, setOpenMap] = useState(false)

  const approveIncident = useCallback((event_id) => handleEvent({ event_id, action: 'approve' }), [])

  const order = incident?.order
  const order_id = order?.order_id
  const vehicle = order?.active_transport?.vehicle
  const source = order?.source
  const destination = order?.destination
  const driver_number = incident?.driver?.auth?.gsm
  const driver_id = incident?.driver?.user_id
  const customer_number = order?.loads?.[0]?.src_phone || order?.do?.auth?.gsm
  const incidentBadgeColor = incidentBadgeContent > setting_limit?.value ? 'error' : 'success'

  const buildVehicleMarker = useCallback(Callbacks.buildVehicleMarkerHandler(vehicle), [vehicle])
  const buildSourceMarker = useCallback(Callbacks.buildAddressMarkerHandler(source, 'source'), [source])
  const buildDestinationeMarker = useCallback(Callbacks.buildAddressMarkerHandler(destination, 'destination'), [destination])

  const handleOrangeCall = useCallback(Callbacks.handleOrangeCallHandler(), [])
  const handleCall = useCallback(Callbacks.handleCallHandler(EXTERNAL_PHONE_SERVICE, handleOrangeCall), [EXTERNAL_PHONE_SERVICE, handleOrangeCall])

  const openHistoryPopup = useCallback(Callbacks.openHistoryPopupHandler(openDialog), [openDialog])

  const approve = useCallback(Callbacks.approveHandler(openNotification, openDialog, approveIncident, incident, incidentBadgeColor), [openNotification, openDialog, approveIncident, incident, incidentBadgeColor])
  const refuse = useCallback(Callbacks.refuseHandler(openDialog), [openDialog])

  const keyDownState = {
    driver_id,
    driver_number,
    customer_number,
    openOrder,
    openMap,
    incident
  }

  const keyDownFunctions = {
    setOpenOrder,
    setOpenMap,
    approve,
    handleCall,
    openNotification,
    openDialog,
    refuse,
  }

  const ctrlKeyMap = useCallback(OnKeyDown.ctrlKeyMapHandler(order, keyDownState, keyDownFunctions), [order, keyDownState, keyDownFunctions])
  const altKeyMap = useCallback(OnKeyDown.altKeyMapHandler(order, keyDownState, keyDownFunctions), [order, keyDownState, keyDownFunctions])
  const baseKeyMap = useCallback(OnKeyDown.baseKeyMapHandler(order, keyDownState, keyDownFunctions), [order, keyDownState, keyDownFunctions])
  const onKeyDown = useCallback(OnKeyDown.onKeyDownHandler(ctrlKeyMap, altKeyMap, baseKeyMap), [ctrlKeyMap, altKeyMap, baseKeyMap])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  const mergedProps = {
    openHistoryPopup,
    incidentBadgeContent,
    incidentBadgeColor,
    markers: _.compact([buildVehicleMarker(), buildSourceMarker(), buildDestinationeMarker()]),
    isPairing: _.get(incident, 'name') === 'pairing_request',
    incident,
    event_id,
    order_id,
    openOrder,
    openMap,
    setOpenOrder,
    setOpenMap,
    approve,
    refuse
  }

  return (
    <Loader isLoading={isLoading}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}

export default withContainer
