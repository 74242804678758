import FormatUtils from '@front/squirtle/utils/format'
import moment from 'moment'

class Callbacks {
  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        group_member_status: ['active', 'inactive']
      }
    }
  }

  static onSubmitHandler(billingExport) {
    return async function onSubmit(values) {
      const final_values = {
        ...values,
        group_member_status: _.map(_.get(values, 'group_member_status'), (status) => status === 'active')
      }
      await billingExport(final_values)
    }
  }
}

export default Callbacks
