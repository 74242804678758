import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => ({ event_id, order_id, ...props }) => {
  const { openNotification } = hooks.useNotification()

  const [createComment] = hooks.useModelFunction('comment', 'generate')
  const [handleEvent] = hooks.useModelFunction('event', 'handle')

  const approve = useCallback(() => handleEvent({ event_id, action: 'approve' }), [event_id])
  const refuseIncident = useCallback((event_id, extra) => handleEvent({ event_id, action: 'refuse', extra }), [])

  const sendComment = useCallback(Callbacks.sendCommentHandler(openNotification, createComment, order_id), [openNotification, createComment, order_id])
  const confirmPairing = useCallback(Callbacks.confirmPairingHandler(openNotification, approve), [openNotification, approve])
  const refuse = useCallback(Callbacks.refuseHandler(openNotification, refuseIncident, event_id), [openNotification, refuseIncident, event_id])

  const mergedProps = {
    approve,
    sendComment,
    confirmPairing,
    refuse
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
