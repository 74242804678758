
import React from 'react'
import I18n from '@front/volcanion/utils/translation'
import { Box, Typography, Grid, Step } from '@mui/material'

import { GridStepper } from '@front/squirtle'
import FormHeader, { Title } from '@abra/elements/headers/Form'

import PrimarySection from './Section/Primary'
import QuotationSection from './Section/Quotation'
import StepSection from './Section/Step'
import HistorySection from './Section/History'

const TitleComponent = ({ status, ...props }) =>
  <Box sx={{ display: 'flex' }}>
    <Typography variant={'h5'} sx={{ color: 'white' }}> {`, ${I18n.t('status.label', { count: 1 })}`} </Typography>
    <Typography variant={'h5'} sx={{ color: 'white' }}> {` : ${_.toLower(I18n.t(`order.status.${status}`))}`} </Typography>
  </Box>

const Job = ({ type, ...props }) => <Box sx={{ p: 3 }}>
  <Grid container justifyContent='center'>
    <Grid container item xs={10} rowSpacing={1}>
      <Grid item xs={12} >
        <FormHeader
          labelParams={{ type: I18n.t(`load.${type}.label`, { count: 1 }) }}
          enableActions={false}
        >
          <Title><TitleComponent {...props} /></Title>
        </FormHeader>
      </Grid>
      <Grid item xs={12} >
        <GridStepper groupsCount={[4]} activeStep={0} orientation="vertical">
          <Step> <PrimarySection /> </Step>
          <Step> <QuotationSection />  </Step>
          <Step> <StepSection />  </Step>
          <Step> <HistorySection />  </Step>
        </GridStepper>
      </Grid>
    </Grid>
  </Grid>
</Box>

export default React.memo(Job)
