import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

import { LocalTaxi, CalendarToday } from '@mui/icons-material/'

const Header = props => <>
  <TableCell align='center'><TextHeader label={I18n.t('date.label', { count: 1 })} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('incident.current', { count: 1 })} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('transport.passenger')} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('commercial_formula.label', { count: 1 })} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('address.label', { count: 1 })} /></TableCell>
</>

export default React.memo(Header)
