class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        status: ['active', 'deleted', 'suspended'],
        ...filter
      }
    }
  }

  static formToFilterHandler(group) {
    return function formToFilter(values, extra, meta, state) {
      return {
        ...values,
        status: _.map(values?.status, name => ({ name })),
        group,
      }
    }
  }

  static formToOptionsHandler() {
    return function formToOptions(values, extra, meta, state) {
      if (_.get(values, 'sortBy') === 'status') return { sort: ['active', 'deleted'] }
      return { sort: [_.get(values, 'sortBy')], }
    }
  }
}

export default Callbacks
