import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import { TableHeader, TableRow, FormTable, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Grid } from '@mui/material'

import Header from './Header'
import Row from './Row'

const HistoryList = props =>
  <Grid container justifyContent='center' alignItems='center'>
    <Grid item xs={11} >
      <FormTable
        model_name={'event'}
        loadOnMount={false}
        config={{
          type: 'listener',
          populate: [
            'order.do.customerinfo',
            'order.do.commercial_formula',
            'order.service.contract.formula',
            'order.source',
            'order.destination'
          ],
          search_id: 'search_linked_incident',
          sort: ['producedAt ASC']
        }}
        tableProps={{
          selectRowStateID: 'selected_incident',
          onRowClick: null,
          stickyHeader: true,
          enableRowActions: true,
          enableGlobalActions: false,
          enableToolbar: false,
          enablePagination: false,
          allowEditMode: false,
          allowRowGenerate: false,
          disableCancelRowLabel: false,
          disableSaveRowLabel: false,
          containerProps: { sx: { height: 'max-content' } }
        }}
      >
        <TableHeader><Header /></TableHeader>
        <TableRow><Row /></TableRow>
      </FormTable>
    </Grid>
  </Grid >

export default React.memo(HistoryList)
