import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'
import { MenuSelectorField, OptionSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('contract.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('sort.by')}
        name={'sortBy'}
        options={[
          { label: I18n.t('date.create'), value: 'createdAt' },
          { label: I18n.t('status.label', { count: 1 }), value: 'status' }
        ]}
      />
    </Grid>
    <Grid item xs>
      <OptionSelectorField
        name={'status'}
        label={I18n.t('status.label', { count: 1 })}
        valueType={'checkbox'}
        multiple
        options={[
          { label: <Box color='green' fontWeight={500}> {I18n.t('status.active')}  </Box>, value: 'active' },
          { label: <Box color='orange' fontWeight={500}> {I18n.t('status.suspended')}  </Box>, value: 'suspended' },
          { label: <Box color='red' fontWeight={500}> {I18n.t('status.deleted')}  </Box>, value: 'deleted' },
        ]}
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container justifyContent={'center'} rowSpacing={3}>
          <Title />
          <Row1 />
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(SearchForm)
