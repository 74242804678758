import React, { useEffect, useState } from 'react'
import { hooks } from '@front/volcanion'

import { Box, Grid } from '@mui/material'

import Details from './Details'
import Search from './Search'
import List from './List'

import HookUtils from '@front/volcanion/utils/hooks'

const IncidentPage = props => {
  const incidents = hooks.useSearchListenerResult('event', 'filtered_incident')
  const [selectedIncident, setSelectedIncident] = hooks.useGlobalState('selected_incident')
  const [first_incident] = hooks.useModel('event', [_.get(_.head(incidents), 'event_id')], { single: true })
  const [current_incident] = hooks.useModel('event', [selectedIncident], { single: true })

  useEffect(() => {
    if (!selectedIncident && !!_.get(first_incident, 'active'))
      setSelectedIncident(_.get(first_incident, 'event_id'))
    else if (!!HookUtils.getRecordComplete(current_incident) && !_.get(current_incident, 'active') && !!selectedIncident) setSelectedIncident(null)
  }, [HookUtils.getRecordsWatcher([current_incident, first_incident])])

  return (
    <Box className='safearea'>
      <Grid container spacing={3} direction='row' justifyContent='center' >
        <Grid item xs={2}><Search /></Grid>
        <Grid item xs={9}>
          {!!selectedIncident && <Details event_id={selectedIncident} />}
        </Grid>
      </Grid>
      <Box position='fixed' bottom={0} width={1 / 2} ml={'25%'} boxShadow={3} maxHeight={window.innerHeight - 60} overflow='scroll' >
        <List onRowClick={rowId => setSelectedIncident(rowId)} />
      </Box>
    </Box >
  )
}

export default React.memo(IncidentPage)
