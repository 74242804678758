import React, { useMemo } from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row }) => {
  const delivery_date = FormatUtils.formatDateBackToFront(_.get(row, 'delivery_date'), 'DD/MM/YYYY')
  const status = useMemo(() => FormatUtils.getStatus(row, ['active', 'deleted', 'suspendedUntil', 'suspendedSince']), [row])
  const statusColor = useMemo(() => FormatUtils.getStatusColor(status), [status])

  return <>
    <TableCell size={'small'}><TextCell value={_.get(row, 'license_client_id')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'license_number')} /></TableCell>
    <TableCell size={'small'}><TextCell value={delivery_date} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'billing_category.name_translated')} /></TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'usual_vehicle.info.plaque')} /></TableCell>
    <TableCell size={'small'}><TextCell value={I18n.t(`status.${status}`)} sx={{ color: statusColor, fontWeight: 'bold' }} /></TableCell>
  </>
}
export default React.memo(Row)
