import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'
import { ModelAutocompleteField, TextField } from '@front/squirtle'

const Title = props => <Grid item xs={12}><Typography variant={'h5'}> {I18n.t('equipment.search')}</Typography> </Grid>

const Row1 = props => {
  return (
    <Grid container item xs={8} justifyContent='center' spacing={1}>
      <Grid item xs>
        <ModelAutocompleteField
          name={'equipment_id'}
          label={I18n.t('name.label', { count: 1 })}
          model_name={'equipment'}
          searchMode={'search'}
          autocompleteMutator={q => ({
            name_trkey: { fr: { contains: q } }
          })}
          labelKeys={['name_trkey.fr']}
          config={{
            populate: ['name_trkey']
          }}
          minQueryLength={1}
          clearOnEmpty
        />
      </Grid>
      <Grid item xs><TextField name={'price'} label={I18n.t('price.label')} /></Grid>
      <Grid item xs={2}><TextField name={'currency'} label={I18n.t('fiat.label', { count: 1 })} /></Grid>
    </Grid>
  )
}

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12} sm={6}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container rowSpacing={3} justifyContent={'center'}>
          <Title />
          <Row1 {...props} />
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(SearchForm)
