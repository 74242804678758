import React, { useCallback } from 'react'

import { hooks, Form } from '@front/volcanion'
import { Loader } from '@front/squirtle'
import { HookUtils } from '@front/volcanion/utils'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const [, searchIncidents] = hooks.useModelSearch('event', 'get', {
    initial_filter: {},
    forced_filter: { active: 1 },
    search_id: 'filtered_incident',
    sort: ['producedAt ASC'],
    default_limit: 30,
    enableCount: true,
    watch_type: 'index'
  })

  const formProps = {
    model_name: 'event',
    name: 'event_panel',
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    onSubmit: useCallback(Callbacks.onSubmitHandler(searchIncidents), [searchIncidents]),
    initialValues: {
      name: [
        'pairing_request',
        'driver_request_to_be_called',
        'driver_request_for_replacement',
        'driver_request_for_a_not_loaded',
        'driver_request_for_redispatch',
        'order_not_served',
        'expected_delay',
        'request_delete'
      ]
    },
    initialState: {},
    closeOnSuccess: false,
    submitOnEmpty: true,
    submitOnChange: true,
    status: { isReady: true }
  }

  const mergedProps = {}

  return (
    <Form {...formProps} >
      <Component {...mergedProps} />
    </Form >
  )
}

export default withForm
